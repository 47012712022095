import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const constantRouterMap = [
    /* {
        path: '/',
        redirect: '/home'
    }, */
    //默认进入home页，根据客户端设备类型跳转PC端首页或移动端首页
    {
        path: '/',
        name: 'Home',
        component: () => import('@/components/CN/home/home')
    },
    //首页
    //PC端
    {
        path: '/index',
        name: 'index',
        component: () => import('@/components/CN/index/index'),
    },
    //移动端
    {
        path: '/mIndex',
        name: 'mobileIndex',
        component: () => import('@/components/CN/index/mobileIndex')
    },

    //关于我们(公司简介)详情页面
    //PC端
    {
        path: '/Gywm',
        name: 'GuanYuWoMen',
        component: () => import('@/components/CN/Details/GuanYuWoMen/GuanYuWoMen'),
        children: [{
            path: '/GywmDetails',
            name: 'GuanYuWoMen_Details',
            component: () => import('@/components/CN/Details/GuanYuWoMen/GuanYuWoMen_Details'),

        },]
    },
    //移动端
    {
        path: '/mGywm',
        name: 'moblieGuanYuWoMen',
        component: () => import('@/components/CN/Details/GuanYuWoMen/mobile/GuanYuWoMen.vue'),
        children: [{
            path: '/mGywmDetails',
            name: 'mobileGuanYuWoMen_Details',
            component: () => import('@/components/CN/Details/GuanYuWoMen/mobile/GuanYuWoMen_Details'),

        },]
    },
    //业务领域详情页面
    //PC端
    {
        path: '/Gsyw',
        name: 'GongSiYeWu',
        component: () => import('@/components/CN/Details/GongSiYeWu/GongSiYeWu'),
    },
    {
        path: '/Gsyw/GsywDetails',
        name: 'GongSiYeWu_Details',
        component: () => import('@/components/CN/Details/GongSiYeWu/GongSiYeWu_Details'),
    },
    //移动端
    {
        path: '/mGsyw',
        name: 'mobileGongSiYeWu',
        component: () => import('@/components/CN/Details/GongSiYeWu/mobile/GongSiYeWu'),
    },
    {
        path: '/mGsywDetails',
        name: 'mobileGongSiYeWu_Details',
        component: () => import('@/components/CN/Details/GongSiYeWu/mobile/GongSiYeWu_Details'),
    },
    //法律团队详情页面
    //PC端
    {
        path: '/Fltd',
        name: 'FaLvTuanDui',
        component: () => import('@/components/CN/Details/FaLvTuanDui/FaLvTuanDui'),
        children: [],
    },
    {
        path: '/FltdDetails',
        name: 'FaLvTuanDui_Details',
        component: () => import('@/components/CN/Details/FaLvTuanDui/FaLvTuanDui_Details'),
    },
    //移动端
    {
        path: '/mFltd',
        name: 'mobileFaLvTuanDui',
        component: () => import('@/components/CN/Details/FaLvTuanDui/mobile/FaLvTuanDui'),
        children: [],
    },
    {
        path: '/mFltdDetails',
        name: 'mobileFaLvTuanDui_Details',
        component: () => import('@/components/CN/Details/FaLvTuanDui/mobile/FaLvTuanDui_Details'),
    },
    //联系我们(表单填写页)
    //PC端
    {
        path: '/toService',
        name: 'toService',
        component: () => import('@/components/CN/toService/toService'),
    },
    //移动端
    {
        path: '/mToService',
        name: 'mobileToService',
        component: () => import('@/components/CN/toService/mobileToService'),
    },

    // 英文版页面
    //默认进入home页，根据客户端设备类型跳转PC端首页或移动端首页
    {
        path: '/en/',
        name: 'EnHome',
        component: () => import('@/components/EN/home/home')
    },
    //首页
    //PC端
    {
        path: '/en/index',
        name: 'EnIndex',
        component: () => import('@/components/EN/index/index'),
    },
    //移动端
    {
        path: '/en/mIndex',
        name: 'EnMobileIndex',
        component: () => import('@/components/EN/index/mobileIndex')
    },
    //关于我们(公司简介)详情页面
    //PC端
    {
        path: '/en/Gywm',
        name: 'EnGuanYuWoMen',
        component: () => import('@/components/EN/Details/GuanYuWoMen/GuanYuWoMen'),
        children: [{
            path: '/en/GywmDetails',
            name: 'EnGuanYuWoMen_Details',
            component: () => import('@/components/EN/Details/GuanYuWoMen/GuanYuWoMen_Details'),

        },]
    },
    //移动端
    {
        path: '/en/mGywm',
        name: 'EnMoblieGuanYuWoMen',
        component: () => import('@/components/EN/Details/GuanYuWoMen/mobile/GuanYuWoMen.vue'),
        children: [{
            path: '/en/mGywmDetails',
            name: 'EnMobileGuanYuWoMen_Details',
            component: () => import('@/components/EN/Details/GuanYuWoMen/mobile/GuanYuWoMen_Details'),

        },]
    },
    //业务领域详情页面
    //PC端
    {
        path: '/en/Gsyw',
        name: 'EnGongSiYeWu',
        component: () => import('@/components/EN/Details/GongSiYeWu/GongSiYeWu'),
    },
    {
        path: '/en/Gsyw/GsywDetails',
        name: 'EnGongSiYeWu_Details',
        component: () => import('@/components/EN/Details/GongSiYeWu/GongSiYeWu_Details'),
    },
    //移动端
    {
        path: '/en/mGsyw',
        name: 'EnMobileGongSiYeWu',
        component: () => import('@/components/EN/Details/GongSiYeWu/mobile/GongSiYeWu'),
    },
    {
        path: '/en/mGsywDetails',
        name: 'EnMobileGongSiYeWu_Details',
        component: () => import('@/components/EN/Details/GongSiYeWu/mobile/GongSiYeWu_Details'),
    },
    //法律团队详情页面
    //PC端
    {
        path: '/en/Fltd',
        name: 'EnFaLvTuanDui',
        component: () => import('@/components/EN/Details/FaLvTuanDui/FaLvTuanDui'),
        children: [],
    },
    {
        path: '/en/FltdDetails',
        name: 'EnFaLvTuanDui_Details',
        component: () => import('@/components/EN/Details/FaLvTuanDui/FaLvTuanDui_Details'),
    },
    //移动端
    {
        path: '/en/mFltd',
        name: 'EnMobileFaLvTuanDui',
        component: () => import('@/components/EN/Details/FaLvTuanDui/mobile/FaLvTuanDui'),
        children: [],
    },
    {
        path: '/en/mFltdDetails',
        name: 'EnMobileFaLvTuanDui_Details',
        component: () => import('@/components/EN/Details/FaLvTuanDui/mobile/FaLvTuanDui_Details'),
    },
    //联系我们(表单填写页)
    //PC端
    {
        path: '/en/toService',
        name: 'EnToService',
        component: () => import('@/components/EN/toService/toService'),
    },
    //移动端
    {
        path: '/en/mToService',
        name: 'EnMobileToService',
        component: () => import('@/components/EN/toService/mobileToService'),
    },

]

export default new Router({
    mode: 'history',
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRouterMap
})