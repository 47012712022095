<template>
  <div id="app">
    <transition name="el-fade-in" mode="in-out">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>


export default {
  name: 'App',

}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(49, 49, 49);
  font-family: "思源黑体 Normal";
  margin-top: 0px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

body {
  padding: 0px;
  margin: 0px;
}
</style>
