import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import axios from 'axios'
import VueTypedJs from 'vue-typed-js'
//导入可商用字体  思源黑体 Normal
import "@/assets/WebFont/SiYuanHeiTi-Normal/SiYuanHeiTi_Normal.css"
//导入Element-ui 用作PC端ui设计
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
//导入Mint-ui 用作手机端ui设计
import MintUI from 'mint-ui'
import 'mint-ui/lib/style.css'
Vue.use(MintUI) //移动端
Vue.use(ElementUI); //PC端
// 全局样式
import '@/assets/style/global.css'

Vue.use(VueTypedJs)
Vue.config.productionTip = false
Vue.prototype.$http = axios
new Vue({
  render: h => h(App),
  router
}).$mount('#app')